<template>
  <div>
    <notifications group="notification" position="top right" />

    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="form-group mb-0">
              <vSelect
                @input="onCardSelect"
                class="form-control form-control-solid form-control--card-select"
                placeholder="Select card"
                :options="cards"
                :reduce="(card) => card['id']"
                label="serialNumber"
              >
                <template #option="{ serialNumber }">
                  <span class="mr-3">{{ serialNumber }}</span>
                </template>
              </vSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSelectedCardLoaded" class="row">
      <div class="col-md-4">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div>
              <strong>Serial Number: </strong>{{ selectedCard.serialNumber }}
            </div>
            <div><strong>Saldo: </strong>{{ selectedCard.saldo }}</div>
            <div><strong>Is active: </strong>{{ selectedCard.isActive }}</div>
            <div>
              <strong>Is verified: </strong>{{ selectedCard.isVerified }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group input-group-lg">
                  <input
                    id="cardNewSaldo"
                    v-model="newSaldo"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button
                  @click="submit"
                  type="reset"
                  :disabled="!newSaldo"
                  class="btn btn-primary btn-lg btn-block"
                >
                  Add Saldo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'
import vSelect from 'vue-select'

export default {
  name: 'LoyaltyTopUpNew',
  components: {
    vSelect,
  },
  data() {
    return {
      cards: [],
      isCardsLoaded: false,
      selectedCard: null,
      isSelectedCardLoaded: false,
      newSaldo: 0,
      apiEndpoint: 'https://loyalty.vendingtycoon.eu',
      // apiEndpoint: 'https://127.0.0.1:8004'
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty TopUp Card', route: '' },
    ])

    axios
      .get(`${this.apiEndpoint}/api/v1/cards?pagination=false`)
      .then(({ data }) => {
        this.cards = [...data['hydra:member']]
        this.isCardsLoaded = true
      })
  },
  methods: {
    onCardSelect(id) {
      this.selectedCard = null
      this.isSelectedCardLoaded = false

      axios.get(`${this.apiEndpoint}/api/v1/cards/${id}`).then(({ data }) => {
        this.selectedCard = data
        this.isSelectedCardLoaded = true
      })
    },
    submit() {
      let cardTop = {
        card: this.selectedCard['@id'],
        user: this.selectedCard.user ? this.selectedCard.user['@id'] : null,
        price: this.newSaldo,
        status: 'adminTopUp',
      }

      axios
        .post(`${this.apiEndpoint}/api/v1/card_top_ups`, cardTop)
        .then(({ status }) => {
          if (status === 201) {
            axios
              .patch(
                `${this.apiEndpoint}/api/v1/cards/${this.selectedCard['id']}`,
                {
                  saldo: this.selectedCard.saldo + parseFloat(this.newSaldo),
                },
                {
                  headers: {
                    'Content-Type': 'application/merge-patch+json',
                  },
                }
              )
              .then(() => {
                this.$notify({
                  group: 'notification',
                  type: 'success',
                  title: 'Card successful top up',
                })

                this.$router.push({ name: 'loyalty-top-up-list' })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--card-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }

    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }

    .vs__dropdown-toggle {
    }

    .vs__clear {
      display: none;
    }

    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }

    .vs__no-options {
    }

    .vs__spinner {
    }

    .vs__deselect {
    }

    .vs__active {
    }

    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
